import capitalize from './capitalize';
import date from './date';

const filters = [
  { key: 'capitalize', filter: capitalize },
  { key: 'date', filter: date },
];

export default (vue: any): void => {
  filters.forEach((filter) => { vue.filter(filter.key, filter.filter); });
};
