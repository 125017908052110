export default (value: string): string => {
  if (!value) {
    return '';
  }

  return value
    .split(' ')
    .map(
      (str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`,
    )
    .join(' ');
};
