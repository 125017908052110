<template>
  <v-app>
    <router-view />
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Snackbar from './components/core/Snackbar';

export default {
  name: 'App',
  components: {
    Snackbar,
  },
};
</script>
