import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthenticationGuard from './guards/require-authentication';

const AUTH_META = {
  requiresAuth: true,
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "enrollment-admin" */ '@/layouts/BaseLayout.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
      },
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/DashboardLayout.vue'),
        redirect: '/dashboard',
        meta: { ...AUTH_META },
        children: [
          {
            path: '/',
            component: () => import('@/views/dashboard/Demos.vue'),
            meta: { ...AUTH_META },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(AuthenticationGuard);

export default router;
