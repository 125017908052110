import Vue from 'vue';
import Vuex from 'vuex';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import VuexPersist from 'vuex-persist';
import enrollment from './enrollment';
import { findError } from '../constants/errors';
import common from './common';
import snackbar from './snackbar';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  // Al cambiar esta llave debe cambiarse también en "@/utils/request" para poder obtener el token
  key: 'beereaders-enrollment-admin',
  storage: window.localStorage,
  reducer: (state) => ({
    // @ts-ignore
    currentUser: state.currentUser,
    // @ts-ignore
    userLogged: state.userLogged,
    // @ts-ignore
    common: state.common,
    // @ts-ignore
    enrollment: state.enrollment,
    // @ts-ignore
    userToken: state.userToken,
  }),
});

export default new Vuex.Store({
  state: {
    currentUser: null,
    userLogged: false,
    registerSuccess: null,
    loginError: null,
    registerError: null,
    loading: false,
    userToken: null,
  },
  mutations: {
    SET_CURRENT_USER(state: any, payload: any) {
      state.currentUser = { ...payload };
    },
    SET_USER_TOKEN(state: any, payload: any) {
      state.userToken = payload;
    },
    SET_USER_LOGGED(state: any, payload: any) {
      state.userLogged = payload;
    },
    SET_REGISTER_SUCCESS(state: any, payload: any) {
      state.registerSuccess = payload;
    },
    SET_LOGIN_ERROR(state: any, payload: any) {
      state.loginError = payload;
    },
    SET_REGISTER_ERROR(state: any, payload: any) {
      state.registerError = payload;
    },
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
  },
  actions: {
    async signIn({ commit }) {
      commit('SET_LOADING', true);
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        hd: 'beereaders.com',
      });
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      await signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          // The signed-in user info.
          const { user } = result;
          if (user.email?.endsWith('@beereaders.com')) {
            commit('SET_CURRENT_USER', user);
            commit('SET_USER_LOGGED', user !== null);
            commit('SET_USER_TOKEN', token);
          } else {
            commit('SET_LOGIN_ERROR', { errorCode: 401, errorMessage: 'USER_WITHOUT_ACCESS', error: 'Usuario no pertence a la organización' });
            commit('SET_USER_LOGGED', false);
            commit('SET_CURRENT_USER', null);
          }
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          commit('SET_LOGIN_ERROR', { errorCode, errorMessage, error: credential });
          commit('SET_USER_LOGGED', false);
          commit('SET_CURRENT_USER', null);
        });
      commit('SET_LOADING', false);
    },
    signOut({ commit }) {
      const auth = getAuth();
      signOut(auth).then(() => {
        commit('SET_CURRENT_USER', null);
        commit('SET_USER_LOGGED', false);
      }).catch(({ code, message }) => {
        const error = findError(code);
        commit('SET_LOGIN_ERROR', { code, message, error: error?.key });
        commit('SET_USER_LOGGED', false);
        commit('SET_CURRENT_USER', null);
      });
    },
  },
  modules: {
    enrollment,
    common,
    snackbar,
  },
  plugins: [vuexPersist.plugin],
});
