const error = {
  ADMIN_ONLY_OPERATION: 'operación restringida de administrador',
  ARGUMENT_ERROR: 'error de argumento',
  APP_NOT_AUTHORIZED: 'aplicación no autorizada',
  APP_NOT_INSTALLED: 'aplicación no instalada',
  CAPTCHA_CHECK_FAILED: 'verificación de captcha fallida',
  CODE_EXPIRED: 'código caducado',
  CORDOVA_NOT_READY: 'cordova no esta lista',
  CORS_UNSUPPORTED: 'cors no compatible',
  CREDENTIAL_ALREADY_IN_USE: 'credencial ya en uso',
  CREDENTIAL_MISMATCH: 'desajuste de token personalizado',
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: 'requiere inicio de sesión reciente',
  DEPENDENT_SDK_INIT_BEFORE_AUTH: 'SDK dependiente inicializado antes de la autenticación',
  DYNAMIC_LINK_NOT_ACTIVATED: 'enlace dinámico no activado',
  EMAIL_CHANGE_NEEDS_VERIFICATION: 'cambio de correo electrónico necesita verificación',
  EMAIL_EXISTS: 'correo electrónico ya en uso',
  EMULATOR_CONFIG_FAILED: 'falló la configuración del emulador',
  EXPIRED_OOB_CODE: 'código de acción caducado',
  EXPIRED_POPUP_REQUEST: 'solicitud emergente cancelada',
  INTERNAL_ERROR: 'error interno',
  INVALID_API_KEY: 'clave API no válida',
  INVALID_APP_CREDENTIAL: 'credencial de aplicación no válida',
  INVALID_APP_ID: 'identificación de aplicación no válida',
  INVALID_AUTH: 'token de usuario no válido',
  INVALID_AUTH_EVENT: 'evento de autenticación no válido',
  INVALID_CERT_HASH: 'hash de certificado no válido',
  INVALID_CODE: 'código de verificación no válido',
  INVALID_CONTINUE_URI: 'URI de continuación no válido',
  INVALID_CORDOVA_CONFIGURATION: 'configuración de cordova no válida',
  INVALID_CUSTOM_TOKEN: 'token personalizado no válido',
  INVALID_DYNAMIC_LINK_DOMAIN: 'dominio de enlace dinámico no válido',
  INVALID_EMAIL: 'Correo electrónico no válido',
  INVALID_EMULATOR_SCHEME: 'esquema de emulador no válido',
  INVALID_IDP_RESPONSE: 'credencial no válida',
  INVALID_MESSAGE_PAYLOAD: 'carga de mensaje no válida',
  INVALID_MFA_SESSION: 'sesión multifactor no válida',
  INVALID_OAUTH_CLIENT_ID: 'id de cliente de autenticación no válido',
  INVALID_OAUTH_PROVIDER: 'proveedor de autenticación no válido',
  INVALID_OOB_CODE: 'código de acción no válido',
  INVALID_ORIGIN: 'dominio no autorizado',
  INVALID_PASSWORD: 'contraseña incorrecta',
  INVALID_PERSISTENCE: 'tipo de persistencia no válido',
  INVALID_PHONE_NUMBER: 'número de teléfono no válido',
  INVALID_PROVIDER_ID: 'ID de proveedor no válido',
  INVALID_RECIPIENT_EMAIL: 'correo electrónico del destinatario no válido',
  INVALID_SENDER: 'remitente no válido',
  INVALID_SESSION_INFO: 'identificación de verificación no válida',
  INVALID_TENANT_ID: 'identificación de inquilino no válida',
  MFA_INFO_NOT_FOUND: 'información multifactorial no encontrada',
  MFA_REQUIRED: 'autenticación multifactor requerida',
  MISSING_ANDROID_PACKAGE_NAME: 'falta el nombre del paquete de Android',
  MISSING_APP_CREDENTIAL: 'falta la credencial de la aplicación',
  MISSING_AUTH_DOMAIN: 'se requiere configuración de dominio de autenticación',
  MISSING_CODE: 'falta el código de verificación',
  MISSING_CONTINUE_URI: 'falta continuar uri',
  MISSING_IFRAME_START: 'falta el inicio del iframe',
  MISSING_IOS_BUNDLE_ID: 'id de paquete de iOS faltante',
  MISSING_OR_INVALID_NONCE: 'nonce faltante o no válido',
  MISSING_MFA_INFO: 'falta información de múltiples factores',
  MISSING_MFA_SESSION: 'falta sesión multifactor',
  MISSING_PHONE_NUMBER: 'número de teléfono faltante',
  MISSING_SESSION_INFO: 'id de verificación faltante',
  MODULE_DESTROYED: 'aplicación eliminada',
  NECESIDAD_CONFIRMACIÓN: 'la cuenta existe con una credencial diferente',
  NETWORK_REQUEST_FAILED: 'solicitud de red fallida',
  NULL_USER: 'usuario nulo',
  NO_AUTH_EVENT: 'Sin evento de autenticación',
  NO_SUCH_PROVIDER: 'No hay tal proveedor',
  OPERATION_NOT_ALLOWED: 'Operación no permitida',
  OPERATION_NOT_SUPPORTED: 'Operación no admitida en este entorno',
  POPUP_BLOCKED: 'Ventana emergente bloqueada',
  POPUP_CLOSED_BY_USER: 'Ventana emergente cerrada por el usuario',
  PROVIDER_ALREADY_LINKED: 'Proveedor ya vinculado',
  QUOTA_EXCEEDED: 'Cuota excedida',
  REDIRECT_CANCELLED_BY_USER: 'Redireccionamiento cancelado por el usuario',
  REDIRECT_OPERATION_PENDING: 'Operación de redirección pendiente',
  REJECTED_CREDENTIAL: 'Credencial rechazada',
  SECOND_FACTOR_ALREADY_ENROLLED: 'Segundo factor ya en uso',
  SECOND_FACTOR_LIMIT_EXCEEDED: 'Recuento máximo de segundos factor excedido',
  TENANT_ID_MISMATCH: 'Id. de arrendatario no coincide',
  TIMEOUT: 'Tiempo de espera',
  TOKEN_EXPIRED: 'Token de usuario caducado',
  TOO_MANY_ATTEMPTS_TRY_LATER: 'Demasiadas solicitudes',
  UNAUTHORIZED_DOMAIN: 'URI de continuación no autorizado',
  UNSUPPORTED_FIRST_FACTOR: 'Primer factor no compatible',
  UNSUPPORTED_PERSISTENCE: 'Tipo de persistencia no admitido',
  UNSUPPORTED_TENANT_OPERATION: 'Operación de arrendatario no admitida',
  UNVERIFIED_EMAIL: 'Correo electrónico no verificado',
  USER_CANCELLED: 'Usuario cancelado',
  USER_DELETED: 'Usuario no encontrado',
  USER_DISABLED: 'Usuario deshabilitado',
  USER_MISMATCH: 'Usuario no coincidente',
  USER_SIGNED_OUT: 'Usuario cerrado',
  WEAK_PASSWORD: 'Contraseña débil',
  WEB_STORAGE_UNSUPPORTED: 'Almacenamiento web no compatible',
  ALREADY_INITIALIZED: 'Ya inicializado',
};

export default error;
