import IState from '@/store/contracts/state';

const state = {
  selectedCollege: undefined, // Id of the selected college
  currentCollege: {},
  colleges: [],
  selectedCountry: undefined, // Id of the selected country
  currentCountry: {},
  countries: [],
  selectedStudent: undefined, // Id of the selected student
  currentStudent: {},
  students: [],
  selectedTeacher: undefined, // Id of the selected student
  currentTeacher: {},
  teachers: [],
  totalPages: 0,
};

const mutations = {
  // Colleges mutations
  setSelectedCollege(state: any, collegeId: number) {
    state.selectedCollege = collegeId;
  },
  setCollege(state: any, currentCollege: any) {
    state.currentColelge = currentCollege;
  },
  setColleges(state: any, colleges: Array<any>) {
    state.colleges = colleges;
  },
  // Countries mutations
  setSelectedCountry(state: any, countryId: number) {
    state.selectedCountry = countryId;
  },
  setCountry(state: any, currentCountry: any) {
    state.currentCountry = currentCountry;
  },
  setCountries(state: any, countries: Array<any>) {
    state.countries = countries;
  },
  // students mutations
  setSelectedStudent(state: any, studentId: number) {
    state.selectedStudent = studentId;
  },
  setStudent(state: any, currentStudent: number) {
    state.currentStudent = currentStudent;
  },
  setStudents(state: any, students = []) {
    state.students = [...students];
  },
  // teachers mutations
  setSelectedTeacher(state: any, studentId: number) {
    state.selectedTeacher = studentId;
  },
  setTeacher(state: any, currentStudent: number) {
    state.currentStudent = currentStudent;
  },
  setTeachers(state: any, teachers = []) {
    state.teachers = [...teachers];
  },
  setTotalPages(state: any, totalPages: string | number) {
    state.totalPages = totalPages;
  },
};

const actions = {
  setCurrentCollege(ctx: any, payload: any) {
    ctx.commit('setSelectedCollege', payload.college.id);
    ctx.commit('setCollege', payload.college);
  },
  getColleges(ctx: any) {
  },
  getCollege(ctx: any, payload: any) {
  },
  // Countries actions
  setCountry(ctx: any, payload: any) {
    ctx.commit('setSelectedCountry', payload.country.id);
    ctx.commit('setCountry', payload.country);
  },
  getCountries(ctx: any) {
  },
  getCountry(ctx: any, payload: any) {
    const currCountry = ctx.state.countries.filter((country: { id: any }) => country.id === payload.id);
    return currCountry;
  },
  // Students actions
  setStudent(ctx: any, payload: any) {
    ctx.commit('setSelectedCountry', payload.student.id);
    ctx.commit('setCountry', payload.student);
  },
  getStudents(ctx: any) {
  },
  getStudent(ctx: any, payload: any) {
  },
  setStudentDataTable({ commit }: any, { data = [], totalPages = 0 }: any) {
    commit('setStudents', data);
    commit('setTotalPages', totalPages);
  },
  // Teacher actions
  setTeacher(ctx: any, payload: any) {
    ctx.commit('setSelectedCountry', payload.teacher.id);
    ctx.commit('setCountry', payload.teacher);
  },
  getTeachers(ctx: any) {
  },
  getTeacher(ctx: any, payload: any) {
  },
  setTeachersDataTable({ commit }: any, { data = [], totalPages = 0 }: any) {
    commit('setTeachers', data);
    commit('setTotalPages', totalPages);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
