import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default async (to: any, from: any, next: any) => {
  const { requiresAuth } = to.meta;

  if (!requiresAuth) {
    next();
    return;
  }
  const auth = await getAuth();
  await onAuthStateChanged(auth, (user) => {
    if (user) {
      next();
    } else {
      next('login');
    }
  });
};
