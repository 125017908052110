const DEFAULT_TIMEOUT = 3000;
const DEFAULT_COLOR = 'info';
const DEFAULT_TEXT = '';

export default {
  namespaced: true,
  state: {
    text: '',
    color: '',
    timeout: '',
  },
  mutations: {
    SHOW_MESSAGE(state: any, { text, color, timeout }: any) {
      state.text = text || DEFAULT_TEXT;
      state.color = color || DEFAULT_COLOR;
      state.timeout = timeout || DEFAULT_TIMEOUT;
    },
  },
  actions: {
    snackbarShow({ commit }: any, payload: any) {
      commit('SHOW_MESSAGE', payload);
    },
    snackbarError({ commit }: any, text: string) {
      commit('SHOW_MESSAGE', { text, color: 'error' });
    },
    snackbarSuccess({ commit }: any, text: string) {
      commit('SHOW_MESSAGE', { text, color: 'success' });
    },
    snackbarWarning({ commit }: any, text: string) {
      commit('SHOW_MESSAGE', { text, color: 'warning' });
    },
  },
};
