import DateUtils from '@/utils/date.utils';

const dateUtils = new DateUtils();

export default (value: Date | string, format: any): string => {
  if (!value) {
    return '';
  }

  const dateFormat = !format ? { date: true, hour: true } : format;
  const formattedDate = dateUtils.format(new Date(value), dateFormat);

  return formattedDate.trim();
};
