import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import es from 'vuetify/src/locale/es';
import VuetifyToastPlugin from 'vuetify-toast-snackbar';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});
Vue.use(VuetifyToastPlugin, {
  x: 'right',
  y: 'top',
  classes: [
    'w-100',
  ],
});
export default new Vuetify({
  lang: {
    locales: {
      en, es,
    },
    current: 'es',
  },
});
