const students = {
  create: 'Create New Student',
  active: 'Active',
  inactive: 'Inactive',
  firstname: 'Name',
  lastname: 'Parent Surname',
  lastname2nd: 'Mother Surname',
  course: 'Course',
  courses: 'Courses',
  school: 'School',
  state: 'State',
  edit: 'edit',
  username: 'Username',
  register_date: 'Registered',
  section: 'Section',
  select_section: 'Section',
  general_information: 'Student Information',
  email: 'Email',
  last_access: 'Last access',
  without_last_access: 'Without access',
  create_new_information: '🔑 New password',
  creation_date: 'Creation',
  dni: 'Identification',
  student_name: 'Student Name',
  student_parent_lastname: 'Parent Lastname',
  student_mother_lastname: 'Mother Lastname',
  birthdate: 'Birth Date',
  country: 'Country',
  select_country: 'Select Country',
  grade: 'Grade',
  select_grade: 'Select grade',
  select_school: 'Select School',
  section_required: 'Section is required',
  dni_required: 'Identification is required',
  dni_length_valid: 'The minimum length for the identification is 8 characters',
  dni_with_blank_spaces: 'DNI cointains blank spaces',
  firstname_required: 'Name is required',
  lastname_required: 'The father\'s last name is required',
  lastname2nd_required: 'Mother\'s surname is required',
  birthDate_required: 'Date of birth is required',
  country_required: 'Country is required',
  school_required: 'School is required',
  grade_required: 'Grade is required',
  new_password_was_set: 'Password set successfully',
  users_updated: 'Users successfully updated',
  user_saved: 'User successfully saved',
  users_disabled: '🚫 Student(s) inhabited correctly.',
  users_enabled: '✅ Student(s) enabled correctly.',
  action_error: '⚠️ Something went wrong! Try to perform this same action later.',
  disable_user_btn: 'Disable Student(s)',
  enable_user_btn: 'Enable Student(s)',
  email_invalid: 'Invalid email',
};

export default students;
