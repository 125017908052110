const state = {
  countries: [],
  schools: [],
  schoolList: [],
};

const mutations = {
  SET_COUNTRIES(state: any, payload = []) {
    state.countries = [...payload];
  },
  SET_SCHOOLS(state: any, payload = []) {
    state.schools = [...payload];
  },
  SET_SCHOOL_LIST(state: any, payload = []) {
    state.schoolList = [...payload];
  },
};

const actions = {
  setCountries({ commit }: any, payload = []) {
    commit('SET_COUNTRIES', payload);
  },
  setSchools({ commit }: any, payload = []) {
    commit('SET_SCHOOLS', payload);
  },
  setSchoolList({ commit }: any, payload = []) {
    commit('SET_SCHOOL_LIST', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
