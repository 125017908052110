const students = {
  all_students: '🎓 Todos los estudiantes',
  label: 'estudiantes',
  create: 'Crear Nuevo Estudiante',
  active: 'Activo',
  inactive: 'Inactivo',
  firstname: 'Nombre',
  lastname: 'Apellido P',
  lastname2nd: 'Apellido M',
  course: 'Curso',
  courses: 'Cursos',
  school: 'Colegio',
  state: 'Estatus',
  edit: 'Editar',
  username: 'Usuario',
  register_date: 'Fecha ingreso',
  email: 'Correo',
  last_access: 'Último acceso',
  without_last_access: 'No ha ingresado',
  create_new_information: '🔑 Nueva contraseña',
  section: 'Sección',
  sections: 'Secciones',
  select_section: 'Seleccionar sección',
  general_information: '✍ Información del Estudiante 📓',
  creation_date: 'Fecha de creación',
  dni: 'DNI',
  student_name: 'Nombre del Alumno',
  student_parent_lastname: 'Apellido Paterno',
  student_mother_lastname: 'Apellido Materno',
  birthdate: 'Fecha de Nacimiento',
  country: 'País',
  select_country: 'Seleccionar un País',
  grade: 'Grado',
  grades: 'Grados',
  select_grade: 'Seleccionar un grado',
  select_courses: 'Seleccionar cursos',
  section_required: 'Sección es requerida',
  dni_required: 'DNI es requerido',
  dni_length_valid: 'La longitud mínima para el DNI es de 8 carácteres',
  dni_with_blank_spaces: 'DNI contiene espacios en blanco',
  firstname_required: 'El nombre del alumno es requerido',
  lastname_required: 'El apellido paterno es requerido',
  lastname2nd_required: 'El apellido materno es requerido',
  birthDate_required: 'La fecha de nacimiento es requerida',
  country_required: 'El País es requerido',
  school_required: 'El colegio es requerido',
  grade_required: 'El grado es requerido',
  new_password_was_set: 'Contraseña establecida satisfactoriamente',
  users_updated: 'Usuarios actualizados satisfactoriamente',
  user_saved: 'Usuario guardado satisfactoriamente',
  users_disabled: '🚫 Alumno(s) inhabiitado(s) correctamente.',
  users_enabled: '✅ Alumno(s) habiitado(s) correctamente.',
  action_error: '⚠️ ¡Algo salió mal! Intenta realizar esta misma acción más tarde.',
  disable_user_btn: 'Deshabilitar Alumno(s)',
  enable_user_btn: 'Habilitar Alumno(s)',
  select_school: 'Seleccionar colegio',
  email_invalid: 'Correo inválido',
  student_not_found: 'Usuario no encontrado',
  new_password_not_saved: 'No se pudo establecer la nueva contraseña',
};

export default students;
