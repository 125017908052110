import error from '@/i18n/es/error';
import colleges from '@/i18n/es/colleges';
import students from '@/i18n/es/students';
import reports from '@/i18n/es/reports';

export default {
  hello: 'Hola',
  footer_cs_love: '®BeeReaders 2023',
  login: {
    title: 'Generador de cuentas demos',
    subtitle: 'Ingresa tu usuario y contraseña',
    user_input: 'Usuario',
    password_input: 'Contraseña',
    login_btn: 'Acceder',
    register_btn: 'Registrar',
    password_required: 'Contraseña requerida',
    email_required: 'El correo electrónico es requerido.',
    email_format_validation: 'Formato de correo electrónico incorrecto.',
  },
  layout: {
    colleges: 'Colegios',
    students: '🎓 Estudiantes',
    teachers: '🧑‍🏫 Profesores',
    edition: 'Edición',
  },
  error,
  colleges,
  students,
  reports,
  teachers: {
    label: 'profesores',
    name: 'Nombre',
    create: 'Crear Nuevo Profesor',
    general_information: '✨ Información del Profesor ✨',
    all_teachers: '🧑‍🏫 Todos los profesores',
  },
  common: {
    filter: 'Filtros',
    filter_by: 'Filtrar por',
    to_disable: 'Deshabilitar',
    to_enable: 'Habilitar',
    student: 'Alumno',
    disable_message: 'Al realizar esta acción la cuenta quedará inhabilitada. ¿Estás seguro de querer realizar esta acción?',
    enable_message: 'Al realizar esta acción la cuenta quedará habilitada. ¿Estás seguro de querer realizar esta acción?',
    cancel: 'Cancelar',
    close: 'Cerrar',
    send: 'Enviar',
    to_save: 'Guardar',
    to_select: 'Seleccionar',
    to_transfer: 'Transferir',
    field_required: 'Este campo es requerido',
    an_error_ocurred: 'Ha ocurrido un error',
    support_text: 'Error inesperado en la aplicación.',
    support_desc: 'Por favor tome captura de este error y envie al equipo de tecnología.',
  },
  subscription: {
    overdue: 'Atrasada',
  },
  navigation: {
    colleges: '🚸 Colegios',
    students: '🎓 Estudiantes',
    teachers: '🧑‍🏫 Profesores',
    parents: '👩‍👧 Padres',
    reports: '📊 Reportes',
  },
};
