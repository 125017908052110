const reports = {
  payments_report: 'Payments report',
  payments_report_desc: 'Payment gateway report with multiple filters in excel format.',
  download: 'Download',
  from_date: 'From date',
  to_date: 'To date',
  excluding_demo_data: 'Exclude demo schools and Beereaders emails',
};

export default reports;
