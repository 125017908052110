const reports = {
  payments_report: 'Reporte de pagos',
  payments_report_desc: 'Reporte de la pasarela de pago con múltiples filtros en formato excel.',
  download: 'Descargar',
  from_date: 'Desde fecha',
  to_date: 'Hasta fecha',
  excluding_demo_data: 'Excluir colegios demo y correos Beereaders',
};

export default reports;
