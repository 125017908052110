import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import moment from 'vue-moment';
import momentTimeZone from 'moment-timezone';
import en from './i18n/en';
import es from './i18n/es';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import registerFilters from './filters';

registerFilters(Vue);

// Multi Language Add
Vue.use(VueI18n);
const messages = { en, es };
const defaultLocale = 'es';
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});

Vue.config.productionTip = false;

Vue.use(moment, momentTimeZone);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
