const error = {
  ADMIN_ONLY_OPERATION: 'admin restricted operation',
  ARGUMENT_ERROR: 'argument error',
  APP_NOT_AUTHORIZED: 'app not authorized',
  APP_NOT_INSTALLED: 'app not installed',
  CAPTCHA_CHECK_FAILED: 'captcha check failed',
  CODE_EXPIRED: 'code expired',
  CORDOVA_NOT_READY: 'cordova not ready',
  CORS_UNSUPPORTED: 'cors unsupported',
  CREDENTIAL_ALREADY_IN_USE: 'credential already in use',
  CREDENTIAL_MISMATCH: 'custom token mismatch',
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: 'requires recent login',
  DEPENDENT_SDK_INIT_BEFORE_AUTH: 'dependent sdk initialized before auth',
  DYNAMIC_LINK_NOT_ACTIVATED: 'dynamic link not activated',
  EMAIL_CHANGE_NEEDS_VERIFICATION: 'email change needs verification',
  EMAIL_EXISTS: 'email already in use',
  EMULATOR_CONFIG_FAILED: 'emulator config failed',
  EXPIRED_OOB_CODE: 'expired action code',
  EXPIRED_POPUP_REQUEST: 'cancelled popup request',
  INTERNAL_ERROR: 'internal error',
  INVALID_API_KEY: 'invalid api key',
  INVALID_APP_CREDENTIAL: 'invalid app credential',
  INVALID_APP_ID: 'invalid app id',
  INVALID_AUTH: 'invalid user token',
  INVALID_AUTH_EVENT: 'invalid auth event',
  INVALID_CERT_HASH: 'invalid cert hash',
  INVALID_CODE: 'invalid verification code',
  INVALID_CONTINUE_URI: 'invalid continue uri',
  INVALID_CORDOVA_CONFIGURATION: 'invalid cordova configuration',
  INVALID_CUSTOM_TOKEN: 'invalid custom token',
  INVALID_DYNAMIC_LINK_DOMAIN: 'invalid dynamic link domain',
  INVALID_EMAIL: 'Invalid email',
  INVALID_EMULATOR_SCHEME: 'invalid emulator scheme',
  INVALID_IDP_RESPONSE: 'invalid credential',
  INVALID_MESSAGE_PAYLOAD: 'invalid message payload',
  INVALID_MFA_SESSION: 'invalid multi factor session',
  INVALID_OAUTH_CLIENT_ID: 'invalid oauth client id',
  INVALID_OAUTH_PROVIDER: 'invalid oauth provider',
  INVALID_OOB_CODE: 'invalid action code',
  INVALID_ORIGIN: 'unauthorized domain',
  INVALID_PASSWORD: 'wrong password',
  INVALID_PERSISTENCE: 'invalid persistence type',
  INVALID_PHONE_NUMBER: 'invalid phone number',
  INVALID_PROVIDER_ID: 'invalid provider id',
  INVALID_RECIPIENT_EMAIL: 'invalid recipient email',
  INVALID_SENDER: 'invalid sender',
  INVALID_SESSION_INFO: 'invalid verification id',
  INVALID_TENANT_ID: 'invalid tenant id',
  MFA_INFO_NOT_FOUND: 'multi factor info not found',
  MFA_REQUIRED: 'multi factor auth required',
  MISSING_ANDROID_PACKAGE_NAME: 'missing android pkg name',
  MISSING_APP_CREDENTIAL: 'missing app credential',
  MISSING_AUTH_DOMAIN: 'auth domain config required',
  MISSING_CODE: 'missing verification code',
  MISSING_CONTINUE_URI: 'missing continue uri',
  MISSING_IFRAME_START: 'missing iframe start',
  MISSING_IOS_BUNDLE_ID: 'missing ios bundle id',
  MISSING_OR_INVALID_NONCE: 'missing or invalid nonce',
  MISSING_MFA_INFO: 'missing multi factor info',
  MISSING_MFA_SESSION: 'missing multi factor session',
  MISSING_PHONE_NUMBER: 'missing phone number',
  MISSING_SESSION_INFO: 'missing verification id',
  MODULE_DESTROYED: 'app deleted',
  NEED_CONFIRMATION: 'account exists with different credential',
  NETWORK_REQUEST_FAILED: 'network request failed',
  NULL_USER: 'null user',
  NO_AUTH_EVENT: 'No auth event',
  NO_SUCH_PROVIDER: 'No such provider',
  OPERATION_NOT_ALLOWED: 'Operation not allowed',
  OPERATION_NOT_SUPPORTED: 'Operation not supported in this environment',
  POPUP_BLOCKED: 'Popup blocked',
  POPUP_CLOSED_BY_USER: 'Popup closed by user',
  PROVIDER_ALREADY_LINKED: 'Provider already linked',
  QUOTA_EXCEEDED: 'Quota exceeded',
  REDIRECT_CANCELLED_BY_USER: 'Redirect cancelled by user',
  REDIRECT_OPERATION_PENDING: 'Redirect operation pending',
  REJECTED_CREDENTIAL: 'Rejected credential',
  SECOND_FACTOR_ALREADY_ENROLLED: 'Second factor already in use',
  SECOND_FACTOR_LIMIT_EXCEEDED: 'Maximum second factor count exceeded',
  TENANT_ID_MISMATCH: 'Tenant id mismatch',
  TIMEOUT: 'Timeout',
  TOKEN_EXPIRED: 'User token expired',
  TOO_MANY_ATTEMPTS_TRY_LATER: 'Too many requests',
  UNAUTHORIZED_DOMAIN: 'Unauthorized continue uri',
  UNSUPPORTED_FIRST_FACTOR: 'Unsupported first factor',
  UNSUPPORTED_PERSISTENCE: 'Unsupported persistence type',
  UNSUPPORTED_TENANT_OPERATION: 'Unsupported tenant operation',
  UNVERIFIED_EMAIL: 'Unverified email',
  USER_CANCELLED: 'User cancelled',
  USER_DELETED: 'User not found',
  USER_DISABLED: 'User disabled',
  USER_MISMATCH: 'User mismatch',
  USER_SIGNED_OUT: 'User signed out',
  WEAK_PASSWORD: 'Weak password',
  WEB_STORAGE_UNSUPPORTED: 'Web storage unsupported',
  ALREADY_INITIALIZED: 'Already initialized',
};

export default error;
