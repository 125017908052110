interface Options {
  date: boolean;
  hour: boolean;
}

class DateUtils {
  static isAfter = (date: Date, afterDate: Date, equal: boolean): boolean => {
    afterDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return equal ? date >= afterDate : date > afterDate;
  }

  static isNowAfter = (date: Date, afterDate: Date): boolean => DateUtils.isAfter(
    date, afterDate, true,
  )

  static formatToUS = (value: string): string => {
    const [day, month, year] = value.split('/');
    return `${year}-${month}-${day}`;
  }

  format = (date: Date, options: Options): string => {
    let result = '';

    if (options.date) {
      const day = this.padZeros(date.getUTCDate(), 2);
      const month = this.padZeros(date.getUTCMonth() + 1, 2);
      const year = date.getUTCFullYear();
      result += `${day}/${month}/${year}`;
    }

    if (options.date && options.hour) {
      result += ' ';
    }

    if (options.hour) {
      const hours = this.padZeros(date.getUTCHours(), 2);
      const minutes = this.padZeros(date.getUTCMinutes(), 2);
      const seconds = this.padZeros(date.getUTCSeconds(), 2);
      result += `${hours}:${minutes}:${seconds}`;
    }

    return result;
  }

  padZeros = (n: string | number, length: number): string => {
    const str = n.toString();
    const leadingZerosCount = length > str.length ? length - str.length : 0;
    return '0'.repeat(leadingZerosCount) + str;
  }
}

export default DateUtils;
